// import React from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
// } from 'antd';
// import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
// import type { UploadFile, UploadProps } from 'antd';
// import './folderDetailsStyle.css';

// const { TextArea } = Input;
// const { Dragger } = Upload;

// interface UploadImageFilesProps {
//   // handleAddStep: () => void;
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// const DrawingType_UploadImageFiles: React.FC<UploadImageFilesProps> = ({
//   setSteps,
//   steps,
// }) => {
//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps: any[]) => {
//       const filteredSteps = prevSteps?.filter(
//         (step: any) => step.id !== stepId
//       );

//       // Reset the order of remaining steps
//       return filteredSteps?.map((step: any, index: number) => ({
//         ...step,
//         id: index + 1, // Reset the id to maintain sequential order
//       }));
//     });
//   };

//   const handleChangeImageFiles = (stepId: number, fileList: UploadFile[]) => {
//     const updatedFileList = fileList.slice(-1).map((file) => ({
//       ...file,
//       status: 'done', // Mark the file as successfully uploaded
//     }));

//     setSteps((prevSteps: any[]) =>
//       prevSteps.map((step: any) =>
//         step.id === stepId ? { ...step, imageFilesList: updatedFileList } : step
//       )
//     );
//   };

//   const handlePreviewImageFiles = async (file: UploadFile) => {
//     let src = file.url as string;
//     if (!src) {
//       src = await new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj as File);
//         reader.onload = () => resolve(reader.result as string);
//       });
//     }
//     const image = new Image();
//     image.src = src;
//     const imgWindow = window.open(src);
//     imgWindow?.document.write(image.outerHTML);
//   };

//   const beforeUploadImageFiles = (file: File) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return Upload.LIST_IGNORE; // Prevent the file from being uploaded
//     }
//     return true; // Proceed with the upload
//   };

//   const draggerProps: UploadProps = {
//     name: 'file',
//     multiple: false, // Restrict to single file upload
//     beforeUpload: beforeUploadImageFiles,
//     onPreview: handlePreviewImageFiles,
//   };

//   return (
//     <div>
//       {steps.map((step: any) => (
//         <div
//           key={step.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {step.id}
//               </Tag>
//               {steps.length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(step.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11} className="upload_container">
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Dragger
//                     {...draggerProps}
//                     fileList={step.imageFilesList}
//                     onChange={({ fileList }) => {
//                       const singleFile = fileList.slice(-1); // Restrict to the last uploaded file
//                       handleChangeImageFiles(step.id, singleFile);
//                     }}
//                     className="custom-dragger RICHA"
//                   >

//                     <p className="ant-upload-drag-icon custom-icon">
//                       <UploadOutlined />
//                     </p>
//                     <p className="ant-upload-text ">
//                       Click or drag image file to upload
//                     </p>

//                   </Dragger>
//                 </Form.Item>
//               </Col>

//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>

//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size={'large'}
//                     value={step.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>

//                 <Form.Item
//                   label="Description"
//                   className="field_style field_margin"
//                 >
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={step.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DrawingType_UploadImageFiles;

//----------SHOW IMAGE IN POPUP----------------
// import React, { useState } from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
//   Image,
// } from 'antd';
// import {
//   DeleteOutlined,
//   UploadOutlined,
//   PlusOutlined,
// } from '@ant-design/icons';
// import type { UploadFile, UploadProps } from 'antd';
// import './folderDetailsStyle.css';

// const { TextArea } = Input;

// interface UploadImageFilesProps {
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// const DrawingType_UploadImageFiles: React.FC<UploadImageFilesProps> = ({
//   setSteps,
//   steps,
// }) => {
//   const [previewImage, setPreviewImage] = useState<string>('');
//   const [previewOpen, setPreviewOpen] = useState(false);

//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps: any[]) =>
//       prevSteps
//         ?.filter((step: any) => step.id !== stepId)
//         .map((step: any, index: number) => ({
//           ...step,
//           id: index + 1, // Reset the id to maintain sequential order
//         }))
//     );
//   };

//   const handlePreview = async (file: UploadFile) => {
//     if (!file.url && !file.preview) {
//       file.preview = await new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj as File);
//         reader.onload = () => resolve(reader.result as string);
//       });
//     }
//     setPreviewImage(file.url || (file.preview as string));
//     setPreviewOpen(true);
//   };

//   const beforeUpload = (file: File) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return Upload.LIST_IGNORE; // Prevent the file from being uploaded
//     }
//     return true; // Proceed with the upload
//   };

//   const handleChange = (
//     stepId: number,
//     { fileList }: { fileList: UploadFile[] }
//   ) => {
//     const singleFileList = fileList.slice(-1); // Keep only the latest file
//     setSteps((prevSteps: any[]) =>
//       prevSteps.map((step: any) =>
//         step.id === stepId ? { ...step, imageFilesList: singleFileList } : step
//       )
//     );
//   };

//   const uploadButton = (
//     <div>
//       <PlusOutlined />
//       <div style={{ marginTop: 8 }}>Upload</div>
//     </div>
//   );

//   return (
//     <div>
//       {steps.map((step: any) => (
//         <div
//           key={step.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {step.id}
//               </Tag>
//               {steps.length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(step.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11}>
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Upload
//                     listType="picture-card"
//                     fileList={step.imageFilesList}
//                     onPreview={handlePreview}
//                     onChange={(info) => handleChange(step.id, info)}
//                     beforeUpload={beforeUpload}
//                   >
//                     {step.imageFilesList?.length >= 8 ? null : uploadButton}
//                   </Upload>
//                   {previewImage && (
//                     <Image
//                       wrapperStyle={{ display: 'none' }}
//                       preview={{
//                         visible: previewOpen,
//                         onVisibleChange: (visible) => setPreviewOpen(visible),
//                         afterOpenChange: (visible) =>
//                           !visible && setPreviewImage(''),
//                       }}
//                       src={previewImage}
//                     />
//                   )}
//                 </Form.Item>
//               </Col>

//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>

//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={step.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>

//                 <Form.Item
//                   label="Description"
//                   className="field_style field_margin"
//                 >
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={step.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DrawingType_UploadImageFiles;
// import React, { useState } from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
//   Image,
// } from 'antd';
// import {
//   DeleteOutlined,
//   UploadOutlined,
//   PlusOutlined,
// } from '@ant-design/icons';
// import type { UploadFile, UploadProps } from 'antd';
// import './folderDetailsStyle.css';

// const { TextArea } = Input;

// interface UploadImageFilesProps {
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// const getBase64 = (img: File, callback: (url: string) => void) => {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result as string));
//   reader.readAsDataURL(img);
// };

// const DrawingType_UploadImageFiles: React.FC<UploadImageFilesProps> = ({
//   setSteps,
//   steps,
// }) => {
//   const [previewImage, setPreviewImage] = useState<string>('');
//   const [previewOpen, setPreviewOpen] = useState(false);

//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps: any[]) =>
//       prevSteps
//         ?.filter((step: any) => step.id !== stepId)
//         .map((step: any, index: number) => ({
//           ...step,
//           id: index + 1, // Reset the id to maintain sequential order
//         }))
//     );
//   };

//   const handlePreview = async (file: UploadFile) => {
//     if (!file.url && !file.preview) {
//       getBase64(file.originFileObj as File, (url: string) => {
//         file.preview = url;
//         setPreviewImage(url);
//         setPreviewOpen(true);
//       });
//     } else {
//       setPreviewImage(file.url || (file.preview as string));
//       setPreviewOpen(true);
//     }
//   };

//   const beforeUpload = (file: File) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return Upload.LIST_IGNORE; // Prevent the file from being uploaded
//     }
//     return true; // Proceed with the upload
//   };

//   const handleChange = (
//     stepId: number,
//     { fileList }: { fileList: UploadFile[] }
//   ) => {
//     const singleFileList = fileList.slice(-1); // Keep only the latest file
//     setSteps((prevSteps: any[]) =>
//       prevSteps.map((step: any) =>
//         step.id === stepId ? { ...step, imageFilesList: singleFileList } : step
//       )
//     );
//   };

//   const uploadButton = (
//     <div>
//       <PlusOutlined />
//       <div style={{ marginTop: 8 }}>Upload</div>
//     </div>
//   );

//   return (
//     <div>
//       {steps.map((step: any) => (
//         <div
//           key={step.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {step.id}
//               </Tag>
//               {steps.length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(step.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11}>
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Upload
//                     listType="picture-card"
//                     fileList={step.imageFilesList}
//                     onPreview={handlePreview}
//                     onChange={(info) => handleChange(step.id, info)}
//                     beforeUpload={beforeUpload}
//                     accept="image/*" // Allow only image files in the file picker
//                   >
//                     {step.imageFilesList?.length >= 1 ? null : uploadButton}
//                   </Upload>
//                   {previewImage && (
//                     <Image
//                       wrapperStyle={{ display: 'none' }}
//                       preview={{
//                         visible: previewOpen,
//                         onVisibleChange: (visible) => setPreviewOpen(visible),
//                         afterOpenChange: (visible) =>
//                           !visible && setPreviewImage(''),
//                       }}
//                       src={previewImage}
//                     />
//                   )}
//                 </Form.Item>
//               </Col>

//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>

//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={step.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>

//                 <Form.Item
//                   label="Description"
//                   className="field_style field_margin"
//                 >
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={step.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DrawingType_UploadImageFiles;

// import React, { useState } from 'react';
// import {
//   Upload,
//   Button,
//   Image,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
// } from 'antd';
// import {
//   UploadOutlined,
//   DeleteOutlined,
//   PlusOutlined,
// } from '@ant-design/icons';
// import type { RcFile, UploadFile } from 'antd/es/upload';
// import './folderDetailsStyle.css';

// const { TextArea } = Input;

// type FileType = RcFile;

// const getBase64 = (img: FileType, callback: (url: string) => void) => {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result as string));
//   reader.readAsDataURL(img);
// };

// interface UploadImageFilesProps {
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// const DrawingType_UploadImageFiles: React.FC<UploadImageFilesProps> = ({
//   setSteps,
//   steps,
// }) => {
//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps: any[]) =>
//       prevSteps
//         ?.filter((step: any) => step.id !== stepId)
//         .map((step: any, index: number) => ({
//           ...step,
//           id: index + 1, // Reset the id to maintain sequential order
//         }))
//     );
//   };

//   const handleChange = (stepId: number, file: RcFile) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return;
//     }

//     getBase64(file, (url) => {
//       setSteps((prevSteps: any[]) =>
//         prevSteps.map((step: any) =>
//           step.id === stepId ? { ...step, previewUrl: url } : step
//         )
//       );
//     });
//   };

//   const handleRemove = (stepId: number) => {
//     setSteps((prevSteps: any[]) =>
//       prevSteps.map((step: any) =>
//         step.id === stepId ? { ...step, previewUrl: null } : step
//       )
//     );
//   };

//   return (
//     <div>
//       {steps.map((step: any) => (
//         <div
//           key={step.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {step.id}
//               </Tag>
//               {steps.length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(step.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11}>
//                 <Form.Item label="Upload Image" className="field_style">
//                   {!step.previewUrl ? (
//                     <Upload
//                       name="image"
//                       accept="image/*"
//                       showUploadList={false}
//                       beforeUpload={(file) => {
//                         handleChange(step.id, file);
//                         return false; // Prevent actual upload to a server
//                       }}
//                       maxCount={1}
//                     >
//                       <Button icon={<UploadOutlined />}>Click to Upload</Button>
//                     </Upload>
//                   ) : (
//                     <div style={{ textAlign: 'center' }}>
//                       <Image
//                         width={300}
//                         src={step.previewUrl}
//                         alt="Preview"
//                         style={{
//                           border: '1px solid #d9d9d9',
//                           borderRadius: '4px',
//                         }}
//                       />
//                       <Button
//                         type="primary"
//                         danger
//                         onClick={() => handleRemove(step.id)}
//                         style={{ marginTop: 10 }}
//                       >
//                         Remove Image
//                       </Button>
//                     </div>
//                   )}
//                 </Form.Item>
//               </Col>

//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>

//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={step.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>

//                 <Form.Item
//                   label="Description"
//                   className="field_style field_margin"
//                 >
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={step.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DrawingType_UploadImageFiles;

//------working code------
// import React, { useState } from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
//   Image,
// } from 'antd';
// import {
//   DeleteOutlined,
//   UploadOutlined,
//   PlusOutlined,
// } from '@ant-design/icons';
// import type { UploadFile, UploadProps } from 'antd';
// import './folderDetailsStyle.css';

// const { TextArea } = Input;

// interface UploadImageFilesProps {
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// const DrawingType_UploadImageFiles: React.FC<UploadImageFilesProps> = ({
//   setSteps,
//   steps,
// }) => {
//   const [previewImage, setPreviewImage] = useState<string>('');
//   const [previewOpen, setPreviewOpen] = useState(false);

//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps: any[]) =>
//       prevSteps
//         ?.filter((step: any) => step.id !== stepId)
//         .map((step: any, index: number) => ({
//           ...step,
//           id: index + 1, // Reset the id to maintain sequential order
//         }))
//     );
//   };

//   const handlePreview = async (file: UploadFile) => {
//     if (!file.url && !file.preview) {
//       file.preview = await new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj as File);
//         reader.onload = () => resolve(reader.result as string);
//       });
//     }
//     setPreviewImage(file.url || (file.preview as string));
//     setPreviewOpen(true);
//   };

//   const beforeUpload = (file: File) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return Upload.LIST_IGNORE; // Prevent the file from being uploaded
//     }
//     return true; // Proceed with the upload
//   };

//   const handleChange = (
//     stepId: number,
//     { fileList }: { fileList: UploadFile[] }
//   ) => {
//     const singleFileList = fileList.slice(-1); // Keep only the latest file
//     setSteps((prevSteps: any[]) =>
//       prevSteps.map((step: any) =>
//         step.id === stepId ? { ...step, imageFilesList: singleFileList } : step
//       )
//     );
//   };

//   const uploadButton = (
//     <div>
//       <PlusOutlined />
//       <div style={{ marginTop: 8 }}>Upload</div>
//     </div>
//   );

//   return (
//     <div>
//       {steps.map((step: any) => (
//         <div
//           key={step.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {step.id}
//               </Tag>
//               {steps.length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(step.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11}>
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Upload
//                     listType="picture-card"
//                     fileList={step.imageFilesList}
//                     onPreview={handlePreview}
//                     onChange={(info) => handleChange(step.id, info)}
//                     beforeUpload={beforeUpload}
//                     accept="image/*" // Restrict file selection to images only
//                   >
//                     {step.imageFilesList?.length >= 1 ? null : uploadButton}
//                   </Upload>
//                   {previewImage && (
//                     <Image
//                       wrapperStyle={{ display: 'none' }}
//                       preview={{
//                         visible: previewOpen,
//                         onVisibleChange: (visible) => setPreviewOpen(visible),
//                         afterOpenChange: (visible) =>
//                           !visible && setPreviewImage(''),
//                       }}
//                       src={previewImage}
//                     />
//                   )}
//                 </Form.Item>
//               </Col>

//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>

//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={step.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>

//                 <Form.Item
//                   label="Description"
//                   className="field_style field_margin"
//                 >
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={step.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DrawingType_UploadImageFiles;
// import React, { useState } from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
//   Image,
//   Button,
// } from 'antd';
// import { DeleteOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
// import type { UploadFile } from 'antd/es/upload/interface';

// const { TextArea } = Input;

// interface Step {
//   id: number;
//   imageFilesList: UploadFile[];
//   smiles_string: string;
//   description: string;
// }

// interface UploadImageFilesProps {
//   setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
//   steps: Step[];
// }

// const DrawingType_UploadImageFiles: React.FC<UploadImageFilesProps> = ({
//   setSteps,
//   steps,
// }) => {
//   const [previewImage, setPreviewImage] = useState<string>('');
//   const [previewOpen, setPreviewOpen] = useState<boolean>(false);

//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps) =>
//       prevSteps
//         ?.filter((step) => step.id !== stepId)
//         .map((step, index) => ({
//           ...step,
//           id: index + 1, // Reset IDs to maintain sequential order
//         }))
//     );
//   };

//   const handlePreview = async (file: UploadFile) => {
//     if (!file.url && !file.preview) {
//       file.preview = await new Promise<string>((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj as File);
//         reader.onload = () => resolve(reader.result as string);
//       });
//     }
//     setPreviewImage(file.url || (file.preview as string));
//     setPreviewOpen(true);
//   };

//   const beforeUpload = (file: File) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return Upload.LIST_IGNORE;
//     }
//     return true;
//   };

//   const handleChange = (stepId: number, fileList: UploadFile[]) => {
//     const singleFileList = fileList.slice(-1); // Only keep the last uploaded file
//     setSteps((prevSteps) =>
//       prevSteps.map((step) =>
//         step.id === stepId ? { ...step, imageFilesList: singleFileList } : step
//       )
//     );
//   };

//   const handleAddStep = () => {
//     setSteps((prevSteps) => [
//       ...prevSteps,
//       {
//         id: prevSteps.length + 1,
//         imageFilesList: [],
//         smiles_string: '',
//         description: '',
//       },
//     ]);
//   };

//   return (
//     <div>
//       {steps.map((step) => (
//         <div
//           key={step.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {step.id}
//               </Tag>
//               {steps.length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(step.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11}>
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Upload
//                     listType="picture"
//                     maxCount={1}
//                     fileList={step.imageFilesList}
//                     onPreview={handlePreview}
//                     onChange={({ fileList }) =>
//                       handleChange(step.id, fileList)
//                     }
//                     beforeUpload={beforeUpload}
//                     accept="image/*"
//                   >
//                     <Button icon={<UploadOutlined />}>Upload</Button>
//                   </Upload>
//                   {previewImage && (
//                     <Image
//                       wrapperStyle={{ display: 'none' }}
//                       preview={{
//                         visible: previewOpen,
//                         onVisibleChange: (visible) => setPreviewOpen(visible),
//                         afterOpenChange: (visible) =>
//                           !visible && setPreviewImage(''),
//                       }}
//                       src={previewImage}
//                     />
//                   )}
//                 </Form.Item>
//               </Col>
//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>
//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={step.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps) =>
//                         prevSteps.map((s) =>
//                           s.id === step.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//                 <Form.Item
//                   label="Description"
//                   className="field_style field_margin"
//                 >
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={step.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps) =>
//                         prevSteps.map((s) =>
//                           s.id === step.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       ))}

//     </div>
//   );
// };

// export default DrawingType_UploadImageFiles;
// import React, { useState } from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
//   Image,
// } from 'antd';
// import {
//   DeleteOutlined,
//   UploadOutlined,
//   PlusOutlined,
// } from '@ant-design/icons';
// import type { UploadFile, UploadProps } from 'antd';
// import './folderDetailsStyle.css';

// const { TextArea } = Input;

// interface UploadImageFilesProps {
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// const DrawingType_UploadImageFiles: React.FC<UploadImageFilesProps> = ({
//   setSteps,
//   steps,
// }) => {
//   const [previewImage, setPreviewImage] = useState<string>('');
//   const [previewOpen, setPreviewOpen] = useState(false);

//   // const handleDeleteStep = (stepId: number) => {
//   //   setSteps((prevSteps: any[]) =>
//   //     prevSteps
//   //       ?.filter((step: any) => step.id !== stepId)
//   //       .map((step: any, index: number) => ({
//   //         ...step,
//   //         id: index + 1, // Reset the id to maintain sequential order
//   //       }))
//   //   );
//   // };
//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps: any[]) => {
//       // First, mark the step as deleted
//       const updatedSteps = prevSteps.map((step) => {
//         if (step.id === stepId) {
//           return {
//             ...step,
//             is_active: false,
//             is_edit: true,
//             isDeleted: true // Add flag to help with UI filtering
//           };
//         }
//         return step;
//       });

//       // Then, reorder the visible steps' display IDs
//       let displayIdCounter = 1;
//       return updatedSteps.map((step) => {
//         if (!step.isDeleted) {
//           return {
//             ...step,
//             displayId: displayIdCounter++ // Add displayId for UI rendering
//           };
//         }
//         return step;
//       });
//     });
//   };
//   const handlePreview = async (file: UploadFile) => {
//     if (!file.url && !file.preview) {
//       file.preview = await new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj as File);
//         reader.onload = () => resolve(reader.result as string);
//       });
//     }
//     setPreviewImage(file.url || (file.preview as string));
//     setPreviewOpen(true);
//   };

//   const beforeUpload = (file: File) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return Upload.LIST_IGNORE; // Prevent the file from being uploaded
//     }
//     return true; // Proceed with the upload
//   };

//   // const handleChange = (
//   //   stepId: number,
//   //   { fileList }: { fileList: UploadFile[] }
//   // ) => {
//   //   const singleFileList = fileList.slice(-1); // Keep only the latest file
//   //   setSteps((prevSteps: any[]) =>
//   //     prevSteps.map((step: any) =>
//   //       step.id === stepId ? { ...step, imageFilesList: singleFileList } : step
//   //     )
//   //   );
//   // };
//   // Modify the handleChange function in DrawingType_UploadImageFiles component
//   const handleChange = (
//     stepId: number,
//     { fileList }: { fileList: UploadFile[] }
//   ) => {
//     const singleFileList = fileList.slice(-1); // Keep only the latest file

//     setSteps((prevSteps: any[]) =>
//       prevSteps.map((step: any) => {
//         if (step.id === stepId) {
//           // Check if this is a new file upload
//           const isNewUpload = singleFileList[0]?.originFileObj !== undefined;

//           return {
//             ...step,
//             imageFilesList: singleFileList,
//             // Set is_edit to true only if there's a new file being uploaded
//             is_edit: isNewUpload,
//           };
//         }
//         return step;
//       })
//     );
//   };
//   const uploadButton = (
//     <div>
//       <PlusOutlined />
//       <div style={{ marginTop: 8 }}>Upload</div>
//     </div>
//   );

//   return (
//     <div>
//        {steps
//         .filter((step: any) => !step.isDeleted) // Only show non-deleted steps
//         .map((step: any) => (
//         <div
//           key={step.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//             <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {step.displayId || step.id} {/* Use displayId for UI */}
//               </Tag>
//               {steps.filter((s: any) => !s.isDeleted).length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(step.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11}>
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Upload
//                     listType="picture-card"
//                     fileList={step.imageFilesList}
//                     onPreview={handlePreview}
//                     onChange={(info) => handleChange(step.id, info)}
//                     beforeUpload={beforeUpload}
//                     accept="image/*" // Restrict file selection to images only
//                   >
//                     {step.imageFilesList?.length >= 1 ? null : uploadButton}
//                   </Upload>
//                   {previewImage && (
//                     <Image
//                       wrapperStyle={{ display: 'none' }}
//                       preview={{
//                         visible: previewOpen,
//                         onVisibleChange: (visible) => setPreviewOpen(visible),
//                         afterOpenChange: (visible) =>
//                           !visible && setPreviewImage(''),
//                       }}
//                       src={previewImage}
//                     />
//                   )}
//                 </Form.Item>
//               </Col>

//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>

//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={step.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>

//                 <Form.Item
//                   label="Description"
//                   className="field_style field_margin"
//                 >
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={step.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps: any[]) =>
//                         prevSteps.map((s: any) =>
//                           s.id === step.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DrawingType_UploadImageFiles;
import React, { useState } from 'react';
import {
  Upload,
  message,
  Input,
  Row,
  Col,
  Form,
  Tag,
  Space,
  Divider,
  Image,
} from 'antd';
import {
  DeleteOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import './folderDetailsStyle.css';

const { TextArea } = Input;

interface UploadImageFilesProps {
  setSteps: (steps: any) => void;
  steps: any;
}

const DrawingType_UploadImageFiles: React.FC<UploadImageFilesProps> = ({
  setSteps,
  steps,
}) => {
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleDeleteStep = (stepId: number) => {
    setSteps((prevSteps: any[]) => {
      // Mark the step as inactive and edited when deleted
      const updatedSteps = prevSteps.map((step) => {
        if (step.id === stepId) {
          return {
            ...step,
            is_active: false,
            is_edit: true,
            isDeleted: true,
          };
        }
        return step;
      });

      // Reorder display IDs for visible steps
      let displayIdCounter = 1;
      return updatedSteps.map((step) => {
        if (step.is_active !== false) {
          return {
            ...step,
            displayId: displayIdCounter++,
          };
        }
        return step;
      });
    });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as File);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = (
    stepId: number,
    { fileList }: { fileList: UploadFile[] }
  ) => {
    const singleFileList = fileList.slice(-1);

    setSteps((prevSteps: any[]) =>
      prevSteps.map((step: any) => {
        if (step.id === stepId) {
          const isNewUpload = singleFileList[0]?.originFileObj !== undefined;
          return {
            ...step,
            imageFilesList: singleFileList,
            is_edit: isNewUpload,
            is_active: true, // Ensure step is active when modified
          };
        }
        return step;
      })
    );
  };

  const handleInputChange = (
    stepId: number,
    field: 'smiles_string' | 'description',
    value: string
  ) => {
    setSteps((prevSteps: any[]) =>
      prevSteps.map((step: any) =>
        step.id === stepId
          ? {
            ...step,
            [field]: value,
            is_edit: true,
            is_active: true, // Ensure step is active when modified
          }
          : step
      )
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      {steps
        .filter((step: any) => step.is_active !== false)
        .map((step: any) => (
          <div
            key={step.id}
            style={{
              marginBottom: '24px',
              // border: '1px solid #ddd',
              // padding: '16px',
              position: 'relative',
            }}
          >
            {/* <Space direction="vertical" size={10} style={{ width: '100%' }}> */}
              {/* <div>
                <Tag
                  style={{
                    backgroundColor: '#0044D3',
                    color: 'white',
                    marginRight: '10px',
                    fontWeight: 600,
                    textAlign: 'center',
                  }}
                >
                  Step {step.displayId || step.id}
                </Tag>
                {steps.filter((s: any) => s.is_active !== false).length > 1 && (
                  <DeleteOutlined
                    style={{
                      color: 'red',
                      fontSize: '16px',
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                    }}
                    onClick={() => handleDeleteStep(step.id)}
                  />
                )}
              </div> */}
              <Row>
                <Col span={24}>
                  <Form.Item label="Upload Image" className="field_style">
                    <Upload
                      listType="picture-card"
                      fileList={step.imageFilesList}
                      onPreview={handlePreview}
                      onChange={(info) => handleChange(step.id, info)}
                      // beforeUpload={beforeUpload}
                      accept="image/*"
                    >
                      {step.imageFilesList?.length >= 1 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>


              </Row>
              {/* <Col
                  span={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Divider type="vertical" style={{ height: '100%' }} />
                </Col> */}

              <Col span={24}>
                <Form.Item
                  label="SMILES string"
                  className="field_style field_margin"
                >
                  <Input
                    className="custom-placeholder"
                    placeholder="Enter SMILES string"
                    size="large"
                    value={step.smiles_string}
                    onChange={(e) =>
                      handleInputChange(
                        step.id,
                        'smiles_string',
                        e.target.value
                      )
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Description"
                  className="field_style field_margin"
                >
                  <TextArea
                    className="custom-placeholder"
                    placeholder="Enter description"
                    rows={5}
                    value={step.description}
                    onChange={(e) =>
                      handleInputChange(
                        step.id,
                        'description',
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
              </Col>
            {/* </Space> */}
          </div>
        ))}
    </div>
  );
};

export default DrawingType_UploadImageFiles;
