import { useState } from 'react';
import { Button, Col, Flex, Form, Row, Input, Popover } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import { getRoleList, createUser } from 'features/Admin/redux/slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import RemarksModal from 'common/RemarksModal';

const { TextArea } = Input;

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  addUserLoading: boolean;
  isEdit?: boolean;
  userDataById?: any;
  handelGetUserList: () => void;
};

export default function UserModal({
  isEdit,
  isOpen,
  onClose,
  addUserLoading,
  userDataById,
  handelGetUserList,
}: TProps) {
  const [form] = Form.useForm();
  const { roleList } = useSelector((state: RootState) => state.admin);
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        first_name: userDataById?.first_name,
        last_name: userDataById?.last_name,
        email: userDataById?.email,
        mobile_phone: userDataById?.mobile_phone,
        employee_id: userDataById?.employee_id,
        role_id: userDataById?.roleDetails?.id,
      });
    }
  }, [userDataById]);

  const dispatch: AppDispatch = useDispatch();

  const { createUserLoading } = useSelector((state: RootState) => state.admin);

  const renderedRoleList = roleList?.map((role) => {
    return { label: role.role, value: role.id };
  });

  const handleDisableUser = () => {
    // console.log('Disabled the user');
  };

  const handleOnFinish = (values: any) => {
    if (isEdit) {
      setIsRemarksModalOpen(false);
      const payload = {
        ...values,
        first_name: values.first_name?.trim(),
        last_name: values.last_name?.trim(),
        id: userDataById.key,
        is_active: true,
        change_remarks: values.remark
      };

      dispatch(createUser(payload)).then((res: any) => {
        if (res.payload.success) {
          onClose();
          form.resetFields();
          handelGetUserList();
        }
      });
    } else {
      const payload = {
        ...values,
        first_name: values.first_name?.trim(),
        last_name: values.last_name?.trim(),
      };
      dispatch(createUser(payload)).then((res: any) => {
        if (res.payload.success) {
          onClose();
          form.resetFields();
          handelGetUserList();
        }
      });
    }
  };
  
  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values) => {
        if (isEdit) {
          if (isRemarksModalOpen) {
            handleOnFinish({ remark, ...values });
          } else {
            setIsRemarksModalOpen(true);
          }
        } else {
          handleOnFinish({ remark, ...values });
        }
      })
      .catch(() => {});
  };

  //fetching roles list
  useEffect(() => {
    dispatch(getRoleList({}));
  }, []);

  const [visible, setVisible] = useState(false);
  const [reason, setReason] = useState('');

  const handleDisableClick = () => {
    // Handle disable action here, e.g., sending reason to an API
    const payload = {
      id: userDataById.key,
      is_active: false,
      blocked_reason: reason, //'Disabled by admin',
    };
    dispatch(createUser(payload)).then((res: any) => {
      if (res.payload.success) {
        onClose();
        form.resetFields();
        handelGetUserList();
      }
    });
    setVisible(false);
    setReason(''); // Reset the reason input after disabling
  };

  const popoverContent = (
    <div>
      <TextArea
        placeholder="Enter reason"
        value={reason}
        // onChange={handleReasonChange}
        onChange={(e) => setReason(e.target.value)}
        autoSize={{ minRows: 1, maxRows: 6 }}
        style={{ marginBottom: 20 }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          size="small"
          onClick={
            () => {
              setVisible(false);
              setReason(''); // Reset the reason input
            }
            // handleCancelClick
          }
          className="font-medium text-xs font-open-sans hover:!text-primary border hover:!border-primary"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={handleDisableClick}
          disabled={!reason}
          className="font-medium text-xs text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-tertiary-filter border hover:border-primary"
        >
          Disable
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      headerTitle={isEdit ? 'Edit User' : 'Add User'}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => form.submit()}
      onCancel={() => form.resetFields()}
      agreeText={'Add User'}
      btnLoading={createUserLoading}
      footerComponent={
        isEdit ? (
          <Flex justify="center" wrap gap={10} className="pb-6">
            <Button
              onClick={() => {
                onClose();
                form.resetFields();
              }}
              className="text-sm font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
            >
              Cancel
            </Button>
            {/* <Popover
              content={popoverContent}
              title="Provide a reason"
              trigger="click"
              visible={visible}
              onVisibleChange={setVisible}
            >
              <Button
                type="primary"
                className="font-semibold text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-tertiary-filter border hover:border-primary"
                onClick={handleDisableUser}
              >
                Disable
              </Button>
            </Popover> */}
            <Button
              type="primary"
              className="font-semibold text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-primary border hover:border-primary"
              onClick={() => handleSubmit('')}
              loading={createUserLoading}
            >
              Update
            </Button>
          </Flex>
        ) : undefined
      }
    >
      <Form
        id="add_user_modal_form"
        layout="vertical"
        onFinish={handleOnFinish}
        form={form}
      >
        <div className="!p-4">
          <Row gutter={10}>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="first_name"
                label={<span className="text-tertiary-dark">First Name</span>}
                defaultStyle={false}
                placeholder="Enter first name"
                required
                validateMsg="First name is required"
              />
            </Col>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="last_name"
                label={<span className="text-tertiary-dark">Last Name</span>}
                defaultStyle={false}
                placeholder="Enter last name"
                required
                validateMsg="Last name is required"
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="employee_id"
                label={<span className="text-tertiary-dark">Employee Id</span>}
                defaultStyle={false}
                placeholder="Enter employee id"
                required
                validateMsg="Employee Id is required"
              />
            </Col>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="role_id"
                label={<span className="text-tertiary-dark">Role</span>}
                defaultStyle={false}
                placeholder="Select role"
                required
                validateMsg="Role is required"
                type="select"
                options={renderedRoleList}
                optionRender={(option: any) => {
                  return <span className="capitalize ">{option.label}</span>;
                }}
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="email"
                label={<span className="text-tertiary-dark">Email</span>}
                defaultStyle={false}
                placeholder="Enter email"
                required
                validateMsg="Email is required"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid e-mail!',
                  },
                ]}
              />
            </Col>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="mobile_phone"
                label={<span className="text-tertiary-dark">Mobile</span>}
                defaultStyle={false}
                placeholder="Enter mobile number"
                rules={[
                  {
                    pattern: /^[0-9]{10}$/,
                    message: 'The input is not valid mobile number!',
                  },
                ]}
                type="number"
              />
            </Col>
          </Row>

          {/* change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen}
            onApprove={(value) => handleSubmit(value)}
            loading={createUserLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </div>
      </Form>
    </Modal>
  );
}
